import React from "react";
import { connect } from "react-redux";
import { getSession } from "../../config/session";
import { Link } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import LoadingOverlay from "react-loading-overlay";
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
import {
  getProductCategoriesAllV2,
  addProductCategory,
  uploadPhoto,
  removePhoto,
} from "../../layouts/Admin/actions/ProductCategoryActions";
import {
  getProductById,
  getInventory,
  getProductStock,
  addMarkdown,
  getMarkdownbyid,
  updateMarkdown,
  getPriceLogsByQuery,
  getPriceLogsByProduct,
} from "../../layouts/Admin/actions/InventoryActions";
import { format, startOfDay, endOfDay } from "date-fns";

import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Table,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import Select from "react-select";
import queryString from "query-string";
import DatePicker from "react-datepicker";

class EditProductMarkdown extends React.Component {
  constructor(props) {
    const markdownId = props.match.params._id;
    const dateToday = format(new Date(), "MMMM d, yyyy");
    const datesStartStr = format(
      startOfDay(new Date()),
      "yyyy-MM-dd'T'HH:mm:ssXXX"
    );
    const datesEndStr = format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss");
    const defaultPage = JSON.parse(getSession("defaultPage"));
    const userData = JSON.parse(getSession("userData"));
    super(props);
    this.state = {
      markdownId: markdownId,
      todayDate: dateToday,
      markdownEntry: {
        products: [],
        effectivityDate: {
          fromDate: datesStartStr,
          toDate: datesEndStr,
        },
        remarks: "",
        user: userData && userData.userId ? userData.userId : "",
        place: defaultPage,
        dateStart: startOfDay(new Date()),
        dateEnd: endOfDay(new Date()),
      },

      inventoryProducts: [],
      selectedProduct: null,
      addedProduct: null,
      placeId: defaultPage,
      isLoading: false,
      submitted: false,
      isSaving: false,
      addProductModalError: "",
      submittedModal: false,
      modal: false,
      outOfStack: false,
      notFoundPrice: false,
    };
  }

  componentDidMount() {
    const userData = JSON.parse(getSession("userData"));

    if (userData == null) {
      this.props.history.push("/login");
      window.location.reload();
    }

    const sessionToken = userData.sessionToken;
    const pageInfo = JSON.parse(getSession("pageInfo"));
    const pageId = pageInfo && pageInfo._id ? pageInfo._id : "";
    let url = this.props.location.search;
    let query = queryString.parse(url);
    let queryStr = "?" + queryString.stringify(query);

    const markdownId = this.state.markdownId;
    this.props.getMarkdownbyid(markdownId, sessionToken, (err, res) => {
      if (!err && res) {
        const markdownEntry = {
          products: res.products,
          effectivityDate: res.effectivityDate,
          remarks: res.remarks,
          user: res.user,
          place: res.place,
          createdAt: res.createdAt,
        };
        const fromDate = res.effectivityDate
          ? new Date(res.effectivityDate.fromDate)
          : null;
        const toDate = res.effectivityDate
          ? new Date(res.effectivityDate.toDate)
          : null;

        this.setState({
          markdownEntry: markdownEntry,
          dateStart: fromDate,
          dateEnd: toDate,
        });
      }
    });
    this.props.getInventory(pageId, queryStr, sessionToken, (err, res) => {
      if (!err && res) {
        if (res.docs && res.docs instanceof Array && res.docs.length > 0) {
          let products = [];
          res.docs.forEach((item) => {
            const productItem = {
              value: item.id,
              label: item.name,
            };
            products.push(productItem);
          });
          this.setState({ inventoryProducts: products });
        }
      }
    });
  }

  showNotification(message) {
    if (message) {
      const notification = {
        place: "tc",
        message: (
          <div>
            <div>{message}</div>
          </div>
        ),
        type: "success",
        icon: "",
        autoDismiss: 5,
      };
      this.refs.notify.notificationAlert(notification);
    }
  }

  showNotificationError(message) {
    if (message) {
      const notification = {
        place: "tc",
        message: (
          <div>
            <div>{message}</div>
          </div>
        ),
        type: "danger",
        icon: "",
        autoDismiss: 5,
      };
      this.refs.notify.notificationAlert(notification);
    }
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
    this.setState({
      selectedProduct: null,
      addedProduct: null,
      addProductModalError: "",
    });
  };

  handleSelectProduct = (e) => {
    const userData = JSON.parse(getSession("userData"));
    this.setState({ outOfStack: false });
    if (userData) {
      const sessionToken = userData.sessionToken;
      const { value: productId } = e;
      this.props.getProductById(productId, sessionToken, (err, res) => {
        if (err) {
          const { response } = err;
          let msg = "";
          if (typeof response === "string") msg = response;
          else {
            if (
              response.data !== null &&
              response.data.message !== null &&
              typeof response.data.message === "string"
            ) {
              msg = response.data.message;
            }
          }
          this.showNotificationError(msg);
        } else {
          const product = res;
          if (e && e.value) {
            let unit = product.unit;
            if (!unit || unit === "")
              unit = product.kind && product.kind === "crops" ? "kg." : "pc.";

            this.setState({
              selectedProduct: product,
              addedProduct: {
                id: product.id,
                name: product.name,
                kind: product.kind,
                markdown: {
                  type: "",
                  fix: 0,
                  percent: 0,
                },
                originalPrice: 0,
                unit,
                volume: {},
                variation: {},
              },
            });
            if (
              product.volumes &&
              product.volumes.length <= 0 &&
              product.variations &&
              product.variations.length <= 0
            ) {
              let queryprice = `?product=${this.state.addedProduct.id}&kind=${this.state.addedProduct.kind}`;
              const querypricelogs = queryString.parse(queryprice);
              this.props.getPriceLogsByProduct(
                querypricelogs,
                sessionToken,
                (_, res) => {
                  let originalPrice = 0;
                  if (res && res.price) {
                    originalPrice = res.price;
                    this.setState({
                      addedProduct: {
                        ...this.state.addedProduct,
                        originalPrice,
                      },
                      notFoundPrice: false,
                    });
                  } else {
                    this.setState({ notFoundPrice: true });
                    this.showNotificationError(
                      "The selected product was price not found. Please select another product."
                    );
                  }
                }
              );
            }
          }
        }
      });
    }
  };
  handleChangeAddProduct = (e) => {
    let { name, value } = e.target;
    if (name === "fix" || name === "originalPrice") {
      const intValue = !isNaN(parseInt(value)) ? parseInt(value) : 0;
      this.setState({
        addedProduct: {
          ...this.state.addedProduct,
          [name]: intValue,
          markdown: {
            ...this.state.addedProduct.markdown,
            [name]: intValue,
          },
        },
      });
    } else if (this.state.addedProduct.markdown.type === "percent") {
      const percent = parseFloat(value) || 0;
      const origPrice = !isNaN(
        parseFloat(this.state.addedProduct.originalPrice)
      )
        ? parseFloat(this.state.addedProduct.originalPrice)
        : 0;
      const markdownPrice = origPrice - [(percent / 100) * origPrice];
      this.setState({
        addedProduct: {
          ...this.state.addedProduct,
          markdown: {
            ...this.state.addedProduct.markdown,
            percent: percent,
            fix: markdownPrice,
          },
        },
      });
    } else {
      this.setState({
        addedProduct: {
          ...this.state.addedProduct,
          [name]: value,
        },
      });
    }
  };

  handleClickRemoveProduct = (e) => {
    const index = e.currentTarget.dataset.idx;
    const products = this.state.markdownEntry.products;
    products.splice(index, 1);
    this.setState({
      markdownEntry: {
        ...this.state.markdownEntry,
        products,
      },
      submitted: false,
    });
  };

  handleSelectVolume = (e) => {
    let { value } = e.target;
    const userData = JSON.parse(getSession("userData"));
    const sessionToken = userData.sessionToken;
    const selectedProduct = this.state.selectedProduct;

    if (selectedProduct !== null && selectedProduct.volumes !== null) {
      let isAdded = false;
      const volume = selectedProduct.volumes.find((i) => i.id === value);

      if (
        this.state.addedProduct.id &&
        this.state.addedProduct.kind &&
        volume.id
      ) {
        const products = this.state.markdownEntry.products;
        const added = products.find(
          (i) =>
            i.id === this.state.addedProduct.id &&
            i.kind === this.state.addedProduct.kind &&
            (!this.state.addedProduct.variation ||
              i.variation.id === this.state.addedProduct.variation.id) &&
            i.volume.id === volume.id
        );

        if (added) {
          isAdded = true;
          this.showNotificationError(
            "The selected item was already added. Please select another volume"
          );
        } else {
          // Construct the query string
          let queryStr = `?kind=${this.state.addedProduct.kind}&volume=${volume.id}`;
          if (this.state.addedProduct.variation) {
            queryStr += `&variation=${this.state.addedProduct.variation.id}`;
          }

          const query = queryString.parse(queryStr);
          this.props.getProductStock(
            this.state.addedProduct.id,
            query,
            sessionToken,
            (_, res) => {
              console.log("Error", res);
              if (res && res.product) {
                if (res.product.reorderPoint) {
                  // Product has a reorder point
                  this.setState({
                    addedProduct: {
                      ...this.state.addedProduct,
                      reorderPoint: res.product.reorderPoint,
                    },
                    outOfStack: false,
                  });
                } else if (res.product.stockCount >= 0) {
                  // Product is out of stock
                  this.setState({ outOfStack: true });
                  this.showNotificationError(
                    "The selected item is out of stock. Please select another volume."
                  );
                }
              } else {
                // No product data in the response
                this.setState({ outOfStack: true });
                this.showNotificationError(
                  "The selected item is out of stock. Please select another volume."
                );
              }
            }
          );

          // Construct the query string for price logs
          let queryprice = `?product=${this.state.addedProduct.id}&kind=${this.state.addedProduct.kind}&volume=${volume.id}`;
          if (this.state.addedProduct.variation) {
            queryprice += `&variation=${this.state.addedProduct.variation.id}`;
          }

          const querypricelogs = queryString.parse(queryprice);
          this.props.getPriceLogsByQuery(
            querypricelogs,
            sessionToken,
            (_, res) => {
              let originalPrice = 0;
              if (res && res.price) {
                originalPrice = res.price;
                this.setState({
                  addedProduct: {
                    ...this.state.addedProduct,
                    originalPrice,
                  },
                  notFoundPrice: false,
                });
              } else {
                this.setState({ notFoundPrice: true });
                this.showNotificationError(
                  "The selected item was price not found. Please select another variation"
                );
              }
            }
          );
        }
      }

      if (!isAdded) {
        this.setState({
          addedProduct: {
            ...this.state.addedProduct,
            volume,
          },
        });
      }
    }
  };

  handleSelectVariation = (e) => {
    let { value } = e.target;
    const selectedProduct = this.state.selectedProduct;
    const userData = JSON.parse(getSession("userData"));
    const sessionToken = userData.sessionToken;
    if (selectedProduct !== null && selectedProduct.variations !== null) {
      let isAdded = false;
      const variation = selectedProduct.variations.find((i) => i.id === value);
      if (
        this.state.addedProduct.id &&
        this.state.addedProduct.kind &&
        variation.id &&
        this.state.addedProduct.volume.id
      ) {
        const products = this.state.markdownEntry.products;
        const added = products.find(
          (i) =>
            i.id === this.state.addedProduct.id &&
            i.kind === this.state.addedProduct.kind &&
            i.variation.id === variation.id &&
            (this.state.addedProduct.volume ||
              i.volume.id === this.state.addedProduct.volume.id)
        );
        if (added) {
          isAdded = true;
          this.showNotificationError(
            "The selected item was already added. Please select another variation"
          );
        } else {
          let queryStr = `?kind=${this.state.addedProduct.kind}&variation=${variation.id}`;
          if (this.state.addedProduct.volume) {
            queryStr += `&volume=${this.state.addedProduct.volume.id}`;
          }

          const query = queryString.parse(queryStr);
          this.props.getProductStock(
            this.state.addedProduct.id,
            query,
            sessionToken,
            (_, res) => {
              console.log("Error", res);
              if (res && res.product) {
                if (res.product.reorderPoint) {
                  // Product has a reorder point
                  this.setState({
                    addedProduct: {
                      ...this.state.addedProduct,
                      reorderPoint: res.product.reorderPoint,
                    },
                    outOfStack: false,
                  });
                } else if (res.product.stockCount >= 0) {
                  // Product is out of stock
                  this.setState({ outOfStack: true });
                  this.showNotificationError(
                    "The selected item is out of stock. Please select another volume."
                  );
                }
              } else {
                // No product data in the response
                this.setState({ outOfStack: true });
                this.showNotificationError(
                  "The selected item is out of stock. Please select another volume."
                );
              }
            }
          );

          let queryprice = `?product=${this.state.addedProduct.id}&kind=${this.state.addedProduct.kind}&variation=${variation.id}`;

          if (this.state.addedProduct.volume) {
            queryprice += `&volume=${this.state.addedProduct.volume.id}`;
          }
          const querypricelogs = queryString.parse(queryprice);
          this.props.getPriceLogsByQuery(
            querypricelogs,
            sessionToken,
            (_, res) => {
              let originalPrice = 0;
              if (res && res.price) {
                originalPrice = res.price;
                this.setState({
                  addedProduct: {
                    ...this.state.addedProduct,
                    originalPrice,
                  },
                  notFoundPrice: false,
                });
              } else {
                this.setState({ notFoundPrice: true });
                this.showNotificationError(
                  "The selected item was price not found. Please select another variation "
                );
              }
            }
          );
        }
      }
      if (!isAdded) {
        this.setState({
          addedProduct: {
            ...this.state.addedProduct,
            variation,
          },
        });
      }
    }
  };
  handleChangeEditMarkdownType = (e) => {
    const index = e.currentTarget.dataset.idx;
    const newType = e.target.value;

    const products = [...this.state.markdownEntry.products];
    const product = { ...products[index] };

    product.markdown.type = newType;
    product.markdown.percent = 0;
    product.markdown.fix = 0;
    products[index] = product;

    this.setState({
      markdownEntry: {
        ...this.state.markdownEntry,
        products,
      },
    });
  };

  handleChangeProduct = (e) => {
    const { name, value } = e.target;
    const index = e.currentTarget.dataset.idx;
    const products = [...this.state.markdownEntry.products];
    const product = { ...products[index] };
    const markdownType = product.markdown.type;

    // Handle fix price input for "fix" markdown type
    if (name === "fix" && markdownType === "fix") {
      const fixValue = parseFloat(value) || 0;
      product.markdown.fix = fixValue;
    }
    // Handle original price input and calculate fix for "percent" type
    else if (name === "originalPrice" && markdownType === "percent") {
      const origPrice = parseFloat(value) || 0;
      const percent = product.markdown.percent || 0;
      product.originalPrice = origPrice;
      product.markdown.fix = origPrice - (percent / 100) * origPrice;
    }
    // Handle percent input and calculate fix price for "percent" markdown type
    else if (name === "percent" && markdownType === "percent") {
      const percent = parseFloat(value) || 0;
      const origPrice = parseFloat(product.originalPrice) || 0;
      product.markdown.percent = percent;
      product.markdown.fix = origPrice - (percent / 100) * origPrice;
    }

    // Update the product in products array and set state
    products[index] = product;
    this.setState({
      markdownEntry: {
        ...this.state.markdownEntry,
        products,
      },
    });
  };

  renderVolumes() {
    if (
      this.state.selectedProduct !== null &&
      this.state.selectedProduct.volumes !== null
    ) {
      const volumes = this.state.selectedProduct.volumes;
      return volumes.map((item, index) => (
        <option key={index} value={item.id}>
          {item.description}
        </option>
      ));
    }
  }
  renderVariations() {
    if (
      this.state.selectedProduct !== null &&
      this.state.selectedProduct.variations !== null
    ) {
      const variations = this.state.selectedProduct.variations;
      return variations.map((item, index) => (
        <option key={index} value={item.id}>
          {item.description}
        </option>
      ));
    }
  }

  handleAddProduct = () => {
    const products = this.state.markdownEntry.products;
    const addedProduct = this.state.addedProduct;
    const isStockError = this.state.outOfStack;
    // const isPriceUnavailable = this.state.notFoundPrice;
    if (addedProduct && addedProduct.id) {
      if (
        isStockError === true
        // !addedProduct.totalCost ||
        // parseFloat(addedProduct.totalCost <= 0) ||
        // !addedProduct.quantity ||
        // isNaN(addedProduct.quantity) ||
        // addedProduct.quantity <= 0 ||
        // !addedProduct.volume ||
        // !addedProduct.variation
      ) {
        this.showNotificationError(
          "The selected item was already out of stock. Please select another item "
        );
        // this.setState({
        //   submittedModal: true,
        //   addProductModalError: "Some field are missing or have invalid values",
        // });
      } else {
        products.push(addedProduct);
        this.setState({
          markdownEntry: {
            ...this.state.markdownEntry,
            products,
          },
          modal: false,
          submittedModal: false,
          addProductModalError: "",
        });
      }
    } else {
      this.setState({
        submittedModal: true,
        addProductModalError: "Please select a product",
      });
    }
  };
  handleChangeMarkdownType = (e) => {
    const selectedType = e.target.value;
    this.setState((prevState) => ({
      addedProduct: {
        ...prevState.addedProduct,
        markdown: {
          ...prevState.addedProduct.markdown,
          type: selectedType,
        },
      },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const userData = JSON.parse(getSession("userData"));

    if (userData) {
      const sessionToken = userData.sessionToken;
      const markdown = { ...this.state.markdownEntry };
      const markdownId = this.state.markdownId;
      let hasError = false;
      if (markdown) {
        this.setState({ submitted: true });

        if (!hasError) {
          if (!window.confirm("Do you want to save this item?")) {
            return false;
          }
          this.setState({ isSaving: true });
          this.props.updateMarkdown(
            markdownId,
            markdown,
            sessionToken,
            (err, res) => {
              if (res) {
                this.setState({ submitted: false, isSaving: false });
                if (res && res.status === "success") {
                  this.showNotification("Mark down has been updated");
                  setTimeout(() => {
                    this.props.history.push("/product-markdown");
                  }, 3000);
                }
              } else {
                if (err) {
                  this.setState({ submitted: false, isSaving: false });
                  const { response } = err;
                  let msg = "";
                  if (typeof response === "string") msg = response;
                  else {
                    if (
                      response.data !== null &&
                      response.data.message !== null &&
                      typeof response.data.message === "string"
                    ) {
                      msg = response.data.message;
                    }
                  }
                  this.setState({ addSupplierModalError: msg });
                } else {
                  this.setState({
                    submitted: false,
                    isSaving: false,
                    addSupplierModalError:
                      "An unknown error occured. Please try again.",
                  });
                }
              }
            }
          );
        }
      } else {
        this.setState({ submitted: true });
        this.showNotificationError(
          "Some fields are required. Please fill in the required fields"
        );
      }
    } else {
      this.props.history.push("/login");
      window.location.reload();
    }
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  renderProductCategoriesMain() {
    if (
      this.state.productCategoriesMain !== undefined ||
      this.state.productCategoriesMain != null
    ) {
      const productCategoriesMain = this.state.productCategoriesMain;
      return productCategoriesMain.map((item, index) => (
        <option key={index} value={item._id}>
          {item.name}
        </option>
      ));
    }
  }
  handleChangeStartDate = (date) => {
    const formattedDateStart = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

    this.setState((prevState) => ({
      dateStart: date,
      markdownEntry: {
        ...prevState.markdownEntry,
        effectivityDate: {
          ...prevState.markdownEntry.effectivityDate,
          fromDate: formattedDateStart,
        },
      },
    }));
  };

  handleChangeEndDate = (date) => {
    const formattedDateEnd = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

    this.setState((prevState) => ({
      dateEnd: date,
      markdownEntry: {
        ...prevState.markdownEntry,
        effectivityDate: {
          ...prevState.markdownEntry.effectivityDate,
          toDate: formattedDateEnd,
        },
      },
    }));
  };

  renderRejectForm(markdownEntry) {
    return (
      <>
        {" "}
        <Row>
          <Col sm="12">
            <Row>
              <Col lg="12" md="12" sm="12">
                <FormGroup>
                  <Alert color="primary">Edit mark down details.</Alert>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="3" md="3" sm="6">
                <FormGroup>
                  <label htmlFor="slug" className="control-label">
                    Date Created
                    <em className="text-muted"></em>
                  </label>
                  <Input
                    readOnly
                    name="createdDate"
                    className="createdDate"
                    placeholder="Date Created"
                    type="text"
                    defaultValue={this.state.todayDate}
                  />
                </FormGroup>
              </Col>
              <Col lg="3" md="3" sm="6">
                <FormGroup>
                  <label htmlFor="name" className="control-label">
                    Effectivity Date From{" "}
                    <em className="text-muted">(Required)</em>
                  </label>
                  <DatePicker
                    id="dateStart"
                    name="dateStart"
                    className="form-control"
                    selectsStart
                    startDate={this.state.dateStart}
                    endDate={this.state.dateEnd}
                    selected={this.state.dateStart}
                    onChange={this.handleChangeStartDate}
                    showTimeSelect={
                      !!this.state.filterDate &&
                      this.state.filterDate === "deliveryDate"
                    }
                    dateFormat={
                      !!this.state.filterDate &&
                      this.state.filterDate === "deliveryDate"
                        ? "MMMM d, yyyy h:mm aa"
                        : "MMMM d, yyyy"
                    }
                    timeIntervals={30}
                    timeCaption="Time"
                    autoComplete="off"
                  />
                </FormGroup>
              </Col>

              <Col lg="3" md="3" sm="6">
                <FormGroup>
                  <label htmlFor="slug" className="control-label">
                    Effectivity Date To{" "}
                    <em className="text-muted">(Required)</em>
                  </label>
                  <DatePicker
                    id="dateEnd"
                    name="dateEnd"
                    className="form-control"
                    selectsEnd
                    startDate={this.state.dateStart}
                    endDate={this.state.dateEnd}
                    selected={this.state.dateEnd} // Selected end date
                    onChange={this.handleChangeEndDate} // Handle date change
                    showTimeSelect={
                      !!this.state.filterDate &&
                      this.state.filterDate === "deliveryDate"
                    }
                    dateFormat={
                      !!this.state.filterDate &&
                      this.state.filterDate === "deliveryDate"
                        ? "MMMM d, yyyy h:mm aa"
                        : "MMMM d, yyyy"
                    }
                    timeIntervals={30}
                    timeCaption="Time"
                    autoComplete="off"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <p className="control-label">Product</p>
                  <Row>
                    <Col>
                      <Table
                        style={{ minWidth: "600px" }}
                        className="tablesorter table-hover"
                        responsive
                        size="sm">
                        <thead className="text-primary">
                          <tr>
                            <th>Product</th>
                            <th>Variation</th>
                            <th>Volume</th>

                            <th>Original Price</th>
                            <th>Mark Down Type</th>
                            <th>Percent</th>

                            <th>Mark Down Price</th>
                            <th> </th>
                          </tr>
                        </thead>
                        <tbody>
                          {markdownEntry.products.length > 0 ? (
                            markdownEntry.products.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <FormGroup
                                      className={
                                        this.state.submitted && !item.name
                                          ? " has-danger"
                                          : ""
                                      }>
                                      <Input
                                        name="name"
                                        readOnly
                                        className="name"
                                        placeholder="Item Name"
                                        type="text"
                                        defaultValue={item.name}
                                        data-idx={i}
                                        onChange={this.handleChangeProduct}
                                      />
                                    </FormGroup>
                                  </td>
                                  <td>
                                    <FormGroup
                                      className={
                                        this.state.submitted &&
                                        !item.variation &&
                                        !item.variation.description
                                          ? " has-danger"
                                          : ""
                                      }>
                                      <Input
                                        name="variation"
                                        readOnly
                                        className="variation"
                                        placeholder="Variation"
                                        type="text"
                                        defaultValue={
                                          item.variation.description
                                        }
                                        data-idx={i}
                                      />
                                    </FormGroup>
                                  </td>
                                  <td>
                                    <FormGroup
                                      className={
                                        this.state.submitted &&
                                        !item.volume &&
                                        !item.volume.description
                                          ? " has-danger"
                                          : ""
                                      }>
                                      <Input
                                        name="volume"
                                        readOnly
                                        className="volume"
                                        placeholder="Volume"
                                        type="text"
                                        defaultValue={item.volume.description}
                                        data-idx={i}
                                      />
                                    </FormGroup>
                                  </td>

                                  <td>
                                    <FormGroup
                                      className={
                                        this.state.submitted &&
                                        (!item.originalPrice ||
                                          isNaN(item.originalPrice))
                                          ? " has-danger"
                                          : ""
                                      }>
                                      <InputGroup>
                                        <InputGroupText
                                          className={
                                            this.state.submitted &&
                                            (!item.originalPrice ||
                                              isNaN(item.originalPrice))
                                              ? "has-danger"
                                              : ""
                                          }>
                                          &#8369;
                                        </InputGroupText>
                                        <Input
                                          name="originalPrice"
                                          className="originalPrice"
                                          placeholder="Price"
                                          readOnly
                                          type="text"
                                          defaultValue={
                                            item.originalPrice
                                              ? item.originalPrice
                                              : 0
                                          }
                                          data-idx={i}
                                          onChange={this.handleChangeProduct}
                                        />
                                      </InputGroup>
                                    </FormGroup>
                                  </td>
                                  <td>
                                    <FormGroup>
                                      <Input
                                        type="select"
                                        data-idx={i}
                                        onChange={
                                          this.handleChangeEditMarkdownType
                                        }
                                        defaultValue={
                                          item.markdown.type
                                            ? item.markdown.type
                                            : ""
                                        }>
                                        <option value="fix">Fix</option>
                                        <option value="percent">Percent</option>
                                      </Input>
                                    </FormGroup>
                                  </td>
                                  <td>
                                    <FormGroup>
                                      <InputGroup>
                                        {item.markdown.type === "fix" ? (
                                          <Input
                                            readOnly
                                            name="percent"
                                            className="percent"
                                            placeholder="Percent"
                                            type="text"
                                            value={
                                              item.markdown.percent
                                                ? item.markdown.percent
                                                : 0
                                            }
                                            data-idx={i}
                                          />
                                        ) : (
                                          <Input
                                            name="percent"
                                            className="percent"
                                            placeholder="Percent"
                                            type="text"
                                            value={
                                              item.markdown.percent
                                                ? item.markdown.percent
                                                : 0
                                            }
                                            data-idx={i}
                                            onChange={this.handleChangeProduct}
                                          />
                                        )}
                                        <InputGroupText>{"%"}</InputGroupText>
                                      </InputGroup>
                                    </FormGroup>
                                  </td>

                                  <td>
                                    <FormGroup>
                                      <InputGroup>
                                        <InputGroupText>&#8369;</InputGroupText>
                                        {item.markdown.type === "percent" ? (
                                          <Input
                                            readOnly
                                            name="fix"
                                            placeholder="Mark down original price"
                                            type="text"
                                            value={
                                              item.markdown.fix
                                                ? item.markdown.fix
                                                : 0
                                            }
                                            data-idx={i}
                                            onChange={this.handleChangeProduct}
                                          />
                                        ) : (
                                          <Input
                                            name="fix"
                                            placeholder="Mark down original price"
                                            type="text"
                                            value={
                                              item.markdown.fix
                                                ? item.markdown.fix
                                                : 0
                                            }
                                            data-idx={i}
                                            onChange={this.handleChangeProduct}
                                          />
                                        )}
                                      </InputGroup>
                                    </FormGroup>
                                  </td>
                                  <td>
                                    <Button
                                      alt="Remove"
                                      title="Remove"
                                      style={{ marginRight: "10px" }}
                                      className="btn-round btn-sm"
                                      color="danger"
                                      type="button"
                                      data-idx={i}
                                      onClick={this.handleClickRemoveProduct}>
                                      <Fa icon="times" />
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <>
                              <tr>
                                <td colSpan={10}>
                                  <h5 className="text-danger">
                                    <em>No products added.</em>
                                  </h5>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <Button
                    alt="Add"
                    title="Add"
                    className="btn-round btn-sm"
                    color="info"
                    type="button"
                    onClick={this.toggleModal}>
                    <Fa icon="plus" />
                    &nbsp; Add Item
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
  render() {
    const pageInfo = JSON.parse(getSession("pageInfo"));
    // let { submitted } = this.state;

    if (pageInfo && pageInfo._id) {
      return (
        <>
          <div className="content">
            <div className="react-notification-alert-container">
              <NotificationAlert ref="notify" />
            </div>
            <Row>
              <Col sm="12" md="12" lg="12">
                <Card>
                  <Form
                    onSubmit={this.handleSubmit}
                    onKeyPress={this.onKeyPress}>
                    <CardHeader>
                      <h4 className="title">Edit Mark Down</h4>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col>
                          {this.renderRejectForm(this.state.markdownEntry)}
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter className="pull-right">
                      <Button className="btn-round" color="info" type="submit">
                        Save
                      </Button>
                      <Link
                        to="/product-markdown"
                        className="btn btn-round btn-light">
                        Cancel
                      </Link>
                    </CardFooter>
                  </Form>
                </Card>
              </Col>
            </Row>
            <LoadingOverlay
              active={this.state.isSaving}
              spinner
              text="Saving..."></LoadingOverlay>

            <div className="modal-section">
              <Modal
                isOpen={this.state.modal}
                toggle={this.toggleModal}
                backdrop="static"
                className={this.props.className}
                size="lg">
                <div className="modal-header">
                  <h4 className="modal-title">Add Product</h4>
                  <button
                    type="button"
                    className="close"
                    onClick={this.toggleModal}
                    aria-label="Close"
                    style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <ModalBody>
                  <form action="">
                    <Row>
                      <Col sm="12" md="6" lg="6">
                        <FormGroup
                          className={
                            this.state.submittedModal &&
                            (!this.state.addedProduct ||
                              !this.state.addedProduct.id)
                              ? "has-danger"
                              : ""
                          }>
                          {this.state.addProductModalError && (
                            <Alert color="danger">
                              {this.state.addProductModalError}
                            </Alert>
                          )}
                          <Select
                            className="react-select"
                            options={this.state.inventoryProducts}
                            onChange={this.handleSelectProduct}
                            placeholder="Select a product"
                            value={this.state.inventoryProducts.filter(
                              (item) =>
                                item.value ===
                                (this.state.selectedProduct &&
                                this.state.selectedProduct.id
                                  ? this.state.selectedProduct.id
                                  : "")
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="12" md="6" lg="6">
                        <FormGroup>
                          <p className="control-label">
                            Kind:&nbsp;
                            {this.state.addedProduct &&
                            this.state.addedProduct.kind
                              ? capitalizeFirstLetter(
                                  this.state.addedProduct.kind
                                )
                              : "Goods"}
                          </p>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {this.state.selectedProduct &&
                        this.state.selectedProduct.variations &&
                        this.state.selectedProduct.variations.length > 0 && (
                          <>
                            <Col sm="12" md="6" lg="6">
                              <FormGroup
                                className={
                                  this.state.submittedModal &&
                                  (!this.state.addedProduct ||
                                    !this.state.addedProduct.variation ||
                                    !this.state.addedProduct.variation.id)
                                    ? " has-danger"
                                    : ""
                                }>
                                <Label
                                  htmlFor="variation"
                                  className="control-label">
                                  Variation
                                </Label>
                                <Input
                                  id="variation"
                                  name="variation"
                                  className="variation"
                                  placeholder="Variation"
                                  type="select"
                                  value={
                                    this.state.addedProduct &&
                                    this.state.addedProduct.variation &&
                                    this.state.addedProduct.variation.id
                                      ? this.state.addedProduct.variation.id
                                      : ""
                                  }
                                  onChange={this.handleSelectVariation}>
                                  <option value="">Select one</option>
                                  {this.renderVariations()}
                                </Input>
                              </FormGroup>
                            </Col>
                          </>
                        )}

                      {this.state.selectedProduct &&
                        this.state.selectedProduct.volumes &&
                        this.state.selectedProduct.volumes.length > 0 && (
                          <>
                            <Col sm="12" md="6" lg="6">
                              <FormGroup
                                className={
                                  this.state.submittedModal &&
                                  (!this.state.addedProduct ||
                                    !this.state.addedProduct.volume ||
                                    !this.state.addedProduct.volume.id)
                                    ? " has-danger"
                                    : ""
                                }>
                                <Label
                                  htmlFor="volume"
                                  className="control-label">
                                  Volume
                                </Label>
                                <Input
                                  id="volume"
                                  name="volume"
                                  className="volume"
                                  placeholder="Volume"
                                  type="select"
                                  value={
                                    this.state.addedProduct &&
                                    this.state.addedProduct.volume &&
                                    this.state.addedProduct.volume.id
                                      ? this.state.addedProduct.volume.id
                                      : ""
                                  }
                                  onChange={this.handleSelectVolume}>
                                  <option value="">Select one</option>
                                  {this.renderVolumes()}
                                </Input>
                              </FormGroup>
                            </Col>
                          </>
                        )}
                      <Col sm="12" md="6" lg="6">
                        <FormGroup
                          className={
                            this.state.submittedModal &&
                            (!this.state.addedProduct ||
                              !this.state.addedProduct.originalPrice ||
                              isNaN(this.state.addedProduct.originalPrice))
                              ? " has-danger"
                              : ""
                          }>
                          <Label
                            htmlFor="originalPrice"
                            className="control-label">
                            Original Price
                          </Label>
                          <InputGroup>
                            <InputGroupText>&#8369;</InputGroupText>
                            <Input
                              readOnly
                              id="originalPrice"
                              name="originalPrice"
                              className="originalPrice"
                              placeholder="originalPrice"
                              type="text"
                              value={
                                this.state.addedProduct &&
                                this.state.addedProduct.originalPrice
                                  ? this.state.addedProduct.originalPrice
                                  : 0
                              }
                              onChange={this.handleChangeAddProduct}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="12" md="6" lg="6">
                        <FormGroup>
                          <Label htmlFor="product" className="control-label">
                            Mark Down Type
                          </Label>
                          <Input
                            type="select"
                            onChange={this.handleChangeMarkdownType}
                            value={
                              this.state.addedProduct &&
                              this.state.addedProduct.markdown &&
                              this.state.addedProduct.markdown.type
                                ? this.state.addedProduct.markdown.type
                                : ""
                            }>
                            <option value="">Select</option>
                            {this.state.addedProduct !== null && (
                              <>
                                <option value="fix">Fix</option>
                                <option value="percent">Percent</option>
                              </>
                            )}
                          </Input>
                        </FormGroup>
                      </Col>
                      {this.state.addedProduct &&
                      this.state.addedProduct.markdown &&
                      this.state.addedProduct.markdown.type === "percent" ? (
                        <Col sm="12" md="6" lg="6">
                          <FormGroup
                            className={
                              this.state.submittedModal &&
                              (!this.state.addedProduct ||
                                !this.state.addedProduct.markdown.percent ||
                                isNaN(this.state.addedProduct.markdown.percent))
                                ? " has-danger"
                                : ""
                            }>
                            <Label htmlFor="Percent" className="control-label">
                              Percent
                            </Label>
                            <InputGroup>
                              <Input
                                id="percent"
                                name="percent"
                                className="percent"
                                placeholder="Percent"
                                type="text"
                                value={
                                  this.state.addedProduct &&
                                  this.state.addedProduct.markdown.percent
                                    ? this.state.addedProduct.markdown.percent
                                    : 0
                                }
                                onChange={this.handleChangeAddProduct}
                              />
                              <InputGroupText
                                className={
                                  this.state.submittedModal &&
                                  (!this.state.addedProduct ||
                                    !this.state.addedProduct.markdown.percent ||
                                    isNaN(
                                      this.state.addedProduct.markdown.percent
                                    ))
                                    ? " has-danger"
                                    : ""
                                }>
                                {"%"}
                              </InputGroupText>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      ) : (
                        ""
                      )}
                      <Col sm="12" md="6" lg="6">
                        {this.state.addedProduct &&
                        this.state.addedProduct.markdown &&
                        this.state.addedProduct.markdown.type === "fix" ? (
                          <FormGroup
                            className={
                              this.state.submittedModal &&
                              (!this.state.addedProduct ||
                                !this.state.addedProduct.markdown.fix ||
                                isNaN(this.state.addedProduct.markdown.fix))
                                ? " has-danger"
                                : ""
                            }>
                            <Label
                              htmlFor="totalCost"
                              className="control-label">
                              Mark Down Price
                            </Label>
                            <InputGroup>
                              <InputGroupText
                                className={
                                  this.state.submittedModal &&
                                  (!this.state.addedProduct ||
                                    !this.state.addedProduct.markdown.fix ||
                                    isNaN(this.state.addedProduct.markdown.fix))
                                    ? " has-danger"
                                    : ""
                                }>
                                &#8369;
                              </InputGroupText>
                              <Input
                                id="fix"
                                name="fix"
                                className="fix"
                                placeholder="Mark down original price"
                                type="text"
                                value={
                                  this.state.addedProduct &&
                                  this.state.addedProduct.markdown.fix
                                    ? this.state.addedProduct.markdown.fix
                                    : 0
                                }
                                onChange={this.handleChangeAddProduct}
                              />
                            </InputGroup>
                          </FormGroup>
                        ) : (
                          ""
                        )}
                        {this.state.addedProduct &&
                        this.state.addedProduct.markdown &&
                        this.state.addedProduct.markdown.type === "percent" ? (
                          <FormGroup
                            className={
                              this.state.submittedModal &&
                              (!this.state.addedProduct ||
                                !this.state.addedProduct.markdown.fix ||
                                isNaN(this.state.addedProduct.markdown.fix))
                                ? " has-danger"
                                : ""
                            }>
                            <Label
                              htmlFor="totalCost"
                              className="control-label">
                              Mark Down Price
                            </Label>
                            <InputGroup>
                              <InputGroupText
                                className={
                                  this.state.submittedModal &&
                                  (!this.state.addedProduct ||
                                    !this.state.addedProduct.markdown.fix ||
                                    isNaN(this.state.addedProduct.markdown.fix))
                                    ? " has-danger"
                                    : ""
                                }>
                                &#8369;
                              </InputGroupText>
                              <Input
                                id="fix"
                                name="fix"
                                readOnly
                                className="markdownPrice"
                                placeholder="Mark down originalPrice"
                                type="text"
                                value={
                                  this.state.addedProduct &&
                                  this.state.addedProduct.markdown.fix
                                    ? this.state.addedProduct.markdown.fix
                                    : 0
                                }
                                onChange={this.handleChangeAddProduct}
                              />
                            </InputGroup>
                          </FormGroup>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </form>
                </ModalBody>
                <ModalFooter
                  style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button color="secondary" onClick={this.toggleModal}>
                    Cancel
                  </Button>

                  <span></span>
                  <Button color="info" onClick={this.handleAddProduct}>
                    {"Add"}
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </div>
        </>
      );
    }
  }
}
const capitalizeFirstLetter = (str) => {
  if (typeof str === "string" && str.length > 0) {
    return str.replace(/^./, str[0].toUpperCase());
  }
};
const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  getProductCategoriesAllV2,
  addProductCategory,
  uploadPhoto,
  removePhoto,
  getProductById,
  getInventory,
  getProductStock,
  addMarkdown,
  getMarkdownbyid,
  updateMarkdown,
  getPriceLogsByQuery,
  getPriceLogsByProduct,
})(EditProductMarkdown);
